import 'babel-polyfill';
import { createRoot } from 'react-dom/client';
import React, { useState, useEffect } from 'react';

const AdvancedSearch = ({
  term,
  exclusionsTerm,
  courtOptions,
  courtChecked,
  caseOptions,
  caseChecked,
  outcomeCategoryOptions,
  outcomeCategorySelected,
  periodOptions,
  periodSelected,
  periodFrom,
  periodTo,
  judge,
  outcome,
  caseName,
  caseNumber,
  summary,
}) => {
  term = decodeURIComponent(term);
  exclusionsTerm = decodeURIComponent(exclusionsTerm);
  judge = decodeURIComponent(judge);
  outcome = decodeURIComponent(outcome);
  caseName = decodeURIComponent(caseName);
  caseNumber = decodeURIComponent(caseNumber);
  summary = decodeURIComponent(summary);

  // 검색 조건 및 검색 조건 UI 순서 상태
  const [searchConditionOptions, setSearchConditionOptions] = useState([]);
  const [conditionCourtOptions, setConditionCourtOptions] = useState([]);
  const [conditionCaseOptions, setConditionCaseOptions] = useState([]);
  const [conditionPeriodOptions, setConditionPeriodOptions] = useState([]);
  const [conditionPeriodSelected, setConditionPeriodSelected] = useState(periodSelected);
  const [conditionOutcomeCatSelected, setConditionOutcomeCatSelected] = useState([]);
  const [searchPeriod, setSearchPeriod] = useState({
    periodFrom: '', // 기간 시작
    periodTo: '', // 기간 종료
  });

  const conditionOptions = [
    {
      key: 'all',
      name: '전체',
    },
    {
      key: 'outcome',
      name: '주문',
    },
    {
      key: 'summary',
      name: '판결요지',
    },
    {
      key: 'judge',
      name: '판사',
    },
    {
      key: 'caseNumber',
      name: '사건번호',
    },
    {
      key: 'caseName',
      name: '사건명',
    },
    {
      key: 'exclusion',
      name: '제외어',
    },
  ];

  useEffect(() => {
    setup();
  }, []);

  useEffect(() => {
    if (conditionPeriodSelected !== 4) {
      setSearchPeriod({
        periodFrom: '',
        periodTo: '',
      });
    }
  }, [conditionPeriodSelected]);

  function setup(mode) {
    // 장고로부터 받아온 검색 조건 데이터로 모두 적재시키는 과정
    const tempConditionCourtOptions = [];
    const tempConditionCaseOptions = [];
    const tempConditionPeriodOptions = [];
    const tempSearchConditionOptions = [
      {
        key: 'all',
        value: (() => {
          if (mode === 'reset') {
            return '';
          } else {
            return !isEmpty(term) ? term : '';
          }
        })(),
      },
      {
        key: 'caseName',
        value: (() => {
          if (mode === 'reset') {
            return '';
          } else {
            return !isEmpty(caseName) ? caseName : '';
          }
        })(),
      },
      {
        key: 'summary',
        value: (() => {
          if (mode === 'reset') {
            return '';
          } else {
            return !isEmpty(summary) ? summary : '';
          }
        })(),
      },
    ];
    setSearchConditionOptions(tempSearchConditionOptions);

    courtOptions.forEach((option) => {
      tempConditionCourtOptions.push({
        key: option.key,
        value: option.value,
        checked: courtChecked.indexOf(option.key) < 0 || mode === 'reset' ? false : true,
      });
    });
    setConditionCourtOptions(tempConditionCourtOptions);

    caseOptions.forEach((value, index) => {
      tempConditionCaseOptions.push({
        key: index,
        value,
        checked: caseChecked.indexOf(index) < 0 || mode === 'reset' ? false : true,
      });
    });
    setConditionCaseOptions(tempConditionCaseOptions);

    periodOptions.forEach((value, index) => {
      tempConditionPeriodOptions.push({
        key: index,
        value,
      });
    });
    setConditionPeriodOptions(tempConditionPeriodOptions);
    if (!isEmpty(periodFrom) || !isEmpty(periodTo)) {
      setSearchPeriod({ periodFrom: periodFrom, periodTo: periodTo });
      setConditionPeriodSelected(periodSelected);
    }
    if (mode === 'reset') {
      setSearchPeriod({ periodFrom: '', periodTo: '' });
      setConditionPeriodSelected(0);
      setConditionOutcomeCatSelected([]);
    }

    if (mode !== 'reset') {
      if (!isEmpty(outcome)) {
        tempSearchConditionOptions.push({
          key: 'outcome',
          value: outcome,
        });
      }

      if (!isEmpty(judge)) {
        tempSearchConditionOptions.push({
          key: 'judge',
          value: judge,
        });
      }

      if (!isEmpty(caseNumber)) {
        tempSearchConditionOptions.push({
          key: 'caseNumber',
          value: caseNumber,
        });
      }

      if (!isEmpty(exclusionsTerm)) {
        tempSearchConditionOptions.push({
          key: 'exclusion',
          value: exclusionsTerm,
        });
      }

      setSearchConditionOptions(tempSearchConditionOptions);
      setConditionOutcomeCatSelected(outcomeCategorySelected);
    }
  }

  function isDuplicateOption(optionKey) {
    return searchConditionOptions.findIndex((option) => option.key === optionKey) >= 0
      ? true
      : false;
  }

  function checkInteger(strval) {
    return /^[1-9]\d*$/.test(strval);
  }

  function handleChangeOption(event, optionIndex) {
    const { tagName, value } = event.target;

    switch (tagName) {
      case 'SELECT': {
        if (!isDuplicateOption(value)) {
          const tempSearchConditionOptions = [...searchConditionOptions];
          tempSearchConditionOptions[optionIndex].key = value;
          setSearchConditionOptions(tempSearchConditionOptions);
        } else {
          snackbar.push('이미 추가된 검색 조건입니다.');
        }

        return;
      }
      case 'INPUT': {
        const tempSearchConditionOptions = [...searchConditionOptions];
        tempSearchConditionOptions[optionIndex].value = value;
        setSearchConditionOptions(tempSearchConditionOptions);

        return;
      }
    }
  }

  function handleAddOption() {
    if (searchConditionOptions.length >= conditionOptions.length) return;

    const avaliableSearchOptions = conditionOptions.filter(
      (conditionOption) =>
        searchConditionOptions.findIndex(
          (searchOption) => searchOption.key === conditionOption.key
        ) < 0
    );

    const tempSearchConditionOptions = [...searchConditionOptions];
    tempSearchConditionOptions.push({
      key: avaliableSearchOptions[0].key,
      value: avaliableSearchOptions[0].key === 'exclusion' ? exclusionsTerm : '',
    });
    setSearchConditionOptions(tempSearchConditionOptions);

    return;
  }

  function handleClickCourtOption(index) {
    const tempCourtOptions = [...conditionCourtOptions];
    tempCourtOptions[index].checked = !tempCourtOptions[index].checked;
    setConditionCourtOptions(tempCourtOptions);
  }

  function handleClickCaseOption(index) {
    const tempCaseOptions = [...conditionCaseOptions];
    tempCaseOptions[index].checked = !tempCaseOptions[index].checked;
    setConditionCaseOptions(tempCaseOptions);
  }

  function getSearchParameter(key) {
    switch (key) {
      case 'sort': {
        return String(0);
      }
      case 'outcome_category': {
        return String(conditionOutcomeCatSelected);
      }
      case 'period': {
        return String(conditionPeriodSelected);
      }
      case 'period_from': {
        return String(searchPeriod.periodFrom);
      }
      case 'period_to': {
        return String(searchPeriod.periodTo);
      }
      case 'court': {
        return conditionCourtOptions
          .filter((court) => court.checked === true)
          .map((court) => court.key);
      }
      case 'case': {
        return conditionCaseOptions
          .filter((court) => court.checked === true)
          .map((court) => court.key);
      }
      default: {
        return searchConditionOptions.find((option) => option.key === key)?.value;
      }
    }
  }

  function isEmpty(variable) {
    if (!Array.isArray(variable)) {
      variable = variable.trim();
    }
    return !variable || variable.length === 0;
  }

  function handleSearch() {
    let existSearchKeyword = false;
    searchConditionOptions.forEach((option) => {
      if (!isEmpty(option.value)) {
        existSearchKeyword = true;
      }
    });

    if (!existSearchKeyword) {
      snackbar.push('검색어를 입력해주세요.');
      return;
    }

    const q = getSearchParameter('all') || '';
    const exclusions = getSearchParameter('exclusion') || '';
    const sort = getSearchParameter('sort') || '';
    const courts = getSearchParameter('court') || '';
    const cases = getSearchParameter('case') || '';
    const period = getSearchParameter('period') || '';
    const periodFrom = getSearchParameter('period_from') || '';
    const periodTo = getSearchParameter('period_to') || '';
    const na = getSearchParameter('caseName') || '';
    const nu = getSearchParameter('caseNumber') || '';
    const s = getSearchParameter('summary') || '';
    const j = getSearchParameter('judge') || '';
    const o = getSearchParameter('outcome') || '';
    const oc = getSearchParameter('outcome_category') || '';

    if (periodFrom !== '' && checkInteger(periodFrom) === false) {
      snackbar.push('시작년도를 잘못 입력하셨습니다');
      return;
    } else if (periodTo !== '' && checkInteger(periodTo) === false) {
      snackbar.push('종료년도를 잘못 입력하셨습니다');
      return;
    }

    const url = [`/search/?q=${encodeURIComponent(q)}`];
    if (!isEmpty(exclusions)) url.push(`exclusion=${encodeURIComponent(exclusions)}`);
    if (!isEmpty(sort)) url.push(`sort=${encodeURIComponent(sort)}`);
    if (!isEmpty(courts)) url.push(`court=${encodeURIComponent(courts)}`);
    if (!isEmpty(cases)) url.push(`case=${encodeURIComponent(cases)}`);
    if (!isEmpty(period)) url.push(`period=${encodeURIComponent(period)}`);
    if (!isEmpty(period) && Number(period) === 4) {
      url.push(`period_from=${encodeURIComponent(periodFrom)}`);
      url.push(`period_to=${encodeURIComponent(periodTo)}`);
    } else {
      if (!isEmpty(periodFrom)) url.push(`period_from=${encodeURIComponent(periodFrom)}`);
      if (!isEmpty(periodTo)) url.push(`period_to=${encodeURIComponent(periodTo)}`);
    }
    if (!isEmpty(na)) url.push(`na=${encodeURIComponent(na)}`);
    if (!isEmpty(nu)) url.push(`nu=${encodeURIComponent(nu)}`);
    if (!isEmpty(s)) url.push(`s=${encodeURIComponent(s)}`);
    if (!isEmpty(j)) url.push(`j=${encodeURIComponent(j)}`);
    if (!isEmpty(o)) url.push(`o=${encodeURIComponent(o)}`);
    if (!isEmpty(oc)) url.push(`oc=${encodeURIComponent(oc)}`);

    window.location.href = url.join('&');
  }

  function handleChangeMultipleSelect(event) {
    const checkedList = [...conditionOutcomeCatSelected];
    const checkedId = Number(event.target.value);
    if (!checkedId) return;

    if (event.target.checked) {
      if (!checkedList.includes(checkedId)) checkedList.push(checkedId);
    } else {
      checkedList.splice(
        checkedList.findIndex((checked) => checked === checkedId),
        1
      );
    }
    setConditionOutcomeCatSelected(checkedList.sort((a, b) => a - b));
  }

  function handleReset() {
    setup('reset');
  }

  function getOutcomeStatus() {
    let name = '전체';
    if (
      conditionOutcomeCatSelected.length > 0 &&
      outcomeCategoryOptions.length - 1 !== conditionOutcomeCatSelected.length
    ) {
      const firstCheckedOption = outcomeCategoryOptions[conditionOutcomeCatSelected[0]][1];
      if (conditionOutcomeCatSelected.length === 1) name = firstCheckedOption;
      else name = `${firstCheckedOption} 외 ${conditionOutcomeCatSelected.length - 1}`;
    }
    return name;
  }

  return (
    <div className="cn-advanced-search">
      <div className="header">
        <p className="title">상세검색</p>
        <button
          className="cn-article-close"
          onClick={() => modal.events.closeByKey('advanced-search')}
        >
          <img src="/static/assets/svgs/common/btn_article_x.svg" />
        </button>
      </div>
      <div className="body">
        <div className="search-options">
          {searchConditionOptions.map((searchOption, optionIndex) => (
            <div className="search-option-item" key={optionIndex}>
              <select
                name="options"
                onChange={(event) => handleChangeOption(event, optionIndex)}
                value={searchOption.key}
              >
                {conditionOptions.map((option) => (
                  <option key={option.key} value={option.key}>
                    {option.name}
                  </option>
                ))}
              </select>
              <input
                type="text"
                value={searchOption.value}
                maxLength="100"
                onChange={(event) => handleChangeOption(event, optionIndex)}
              />
            </div>
          ))}
        </div>
        <div className="search-option-buttons">
          {searchConditionOptions.length < conditionOptions.length && (
            <button className="btn-new-option" onClick={handleAddOption}>
              <img src="/static/assets/svgs/common/ico_add.svg" className="icon-new-option" />
              <span>검색 조건 추가</span>
            </button>
          )}
        </div>
        <div className="search-conditions">
          <div className="cn-search-condition">
            <p className="title">법원</p>
            <ul className="cn-tag-checkbox">
              {conditionCourtOptions.map((option, index) => (
                <li key={index}>
                  <label htmlFor={`court_tag${index}`}>
                    <input
                      type="checkbox"
                      id={`court_tag${index}`}
                      onChange={() => handleClickCourtOption(index)}
                      checked={option.checked}
                    />
                    <div className="tag">
                      <img
                        src="/static/assets/svgs/common/tag_check_on.svg"
                        className="icon-check-on"
                      />
                      <img
                        src="/static/assets/svgs/common/tag_check_off.svg"
                        className="icon-check-off"
                      />
                      <span>{option.value}</span>
                    </div>
                  </label>
                </li>
              ))}
            </ul>
          </div>

          <div className="cn-search-condition">
            <p className="title">사건종류</p>
            <ul className="cn-tag-checkbox">
              {conditionCaseOptions.map((option, index) => (
                <li key={index}>
                  <label htmlFor={`case_type_tag${index}`}>
                    <input
                      type="checkbox"
                      id={`case_type_tag${index}`}
                      onChange={() => handleClickCaseOption(index)}
                      checked={option.checked}
                    />
                    <div className="tag">
                      <img
                        src="/static/assets/svgs/common/tag_check_on.svg"
                        className="icon-check-on"
                      />
                      <img
                        src="/static/assets/svgs/common/tag_check_off.svg"
                        className="icon-check-off"
                      />
                      <span>{option.value}</span>
                    </div>
                  </label>
                </li>
              ))}
            </ul>
          </div>

          <div className="cn-search-condition" style={{ display: 'flex' }}>
            <div style={{ marginRight: 20 }}>
              <p className="title">소송결과</p>
              <div
                className="cn-multiple-select"
                onClick={(event) => multipleSelect.toggle(event.target)}
              >
                <div className="cn-multiple-select-box">
                  <span className="name">{getOutcomeStatus()}</span>
                </div>
                <div
                  className="cn-multiple-options"
                  style={{ top: '-249px', borderTop: '1px solid #e3e3e3' }}
                >
                  {outcomeCategoryOptions.map((option) => {
                    if (!option[0]) return null;
                    return (
                      <div className="option" key={option[0]}>
                        <label htmlFor={`decision_tag${option[0]}`}>
                          <input
                            type="checkbox"
                            id={`decision_tag${option[0]}`}
                            value={option[0]}
                            checked={conditionOutcomeCatSelected.includes(option[0])}
                            onChange={handleChangeMultipleSelect}
                          />
                          <div className="tag">
                            <img
                              src="/static/assets/svgs/common/check_on.svg"
                              className="icon-check-on"
                            />
                            <img
                              src="/static/assets/svgs/common/check_off.svg"
                              className="icon-check-off"
                            />
                            <span>{option[1]}</span>
                          </div>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div>
              <p className="title">기간</p>
              <div className="search-option-item">
                <select
                  name="period"
                  onChange={(event) => setConditionPeriodSelected(Number(event.target.value))}
                  value={conditionPeriodSelected}
                >
                  {conditionPeriodOptions.map((option) => (
                    <option key={option.key} value={option.key}>
                      {option.value}
                    </option>
                  ))}
                </select>

                {conditionPeriodSelected === 4 && (
                  <div id="datepicker" className="datepicker">
                    <div className="date-range">
                      <input
                        id="period_from"
                        type="text"
                        pattern="[0-9]*"
                        size="4"
                        maxLength={4}
                        value={searchPeriod.periodFrom}
                        onChange={(event) =>
                          setSearchPeriod({ ...searchPeriod, periodFrom: event.target.value })
                        }
                        placeholder="시작년도"
                      />
                      <span> - </span>
                      <input
                        id="period_to"
                        type="text"
                        pattern="[0-9]*"
                        size="4"
                        maxLength={4}
                        value={searchPeriod.periodTo}
                        onChange={(event) =>
                          setSearchPeriod({ ...searchPeriod, periodTo: event.target.value })
                        }
                        placeholder="종료년도"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <button className="cn-button btn-primary" onClick={handleSearch}>
          <span>검색</span>
        </button>
        <button className="cn-button btn-default" onClick={handleReset}>
          <span>초기화</span>
        </button>
      </div>
    </div>
  );
};

createRoot(document.getElementById('advanced_search_container')).render(
  <AdvancedSearch
    isProUser={is_pro_user ? true : false}
    isProTrial={is_pro_trial ? true : false}
    term={term}
    exclusionsTerm={exclusions_term}
    courtOptions={court_options}
    courtChecked={court_checked}
    caseOptions={case_options}
    caseChecked={case_checked}
    outcomeCategoryOptions={outcome_category_options}
    outcomeCategorySelected={outcome_category_selected}
    periodOptions={period_options}
    periodSelected={period_selected}
    periodFrom={period_from}
    periodTo={period_to}
    judge={judge}
    outcome={outcome}
    caseName={casename}
    caseNumber={casenumber}
    summary={summary}
  />
);
